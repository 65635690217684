import React, { Component } from "react";
import PowerbiEmbedded from "react-powerbi";
import api from "../utils/api";
import  { PublicClientApplication } from "@azure/msal-browser"
import { error } from "jquery";
const msalConfig = {
  auth: {
    clientId: "9dc3cd7f-f42d-420d-8c8f-22b0fa9cb157",
    authority: "https://login.microsoftonline.com/5f4e175c-44de-4676-ae70-23ec941f455d/"
  }
};
const msalInstance = new PublicClientApplication(msalConfig);
const YOUR_PAGE_ID = "testin123";
const EMBED_TYPE = "report";

class App extends Component {
  state = {
    pbiAccessToken: ""
  };

  componentDidMount() {
    const tokenRequest = {
      scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],

    };
    msalInstance.acquireTokenSilent(tokenRequest).then(response =>{
      this.setState({ pbiAccessToken: response.accessToken});
    }).catch(error => {
      msalInstance.acquireTokenPopup(tokenRequest).then(response => {
        this.setState({ pbiAccessToken: response.accessToken });
      }).catch(err => {
        console.error(err);
      });
    });
  }

  render() {
    if (this.state.pbiAccessToken) {
      return (
        <div className="App">
          <PowerbiEmbedded
            id='4718dc32-c073-48b1-b701-3d73aa151bdd'
            embedUrl='https://app.powerbi.com/reportEmbed?reportId=4718dc32-c073-48b1-b701-3d73aa151bdd&&ctid=5f4e175c-44de-4676-ae70-23ec941f455d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtZi1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9'
            accessToken={this.state.pbiAccessToken}
            filterPaneEnabled={false}
            navContentPaneEnabled={true}
            pageName={`${YOUR_PAGE_ID}`}
            embedType={`${EMBED_TYPE}`}
            width="100%"
            height="900px"
          />
        </div>
      );
    }
    return "";
  }
}

export default App;
